import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../store';
import {combineLatest, Observable} from 'rxjs';
import {Version} from '../../store/versions-data/models/versions-data.model';
import {MatSelectChange} from '@angular/material/select';
import {
  selectAvailableVersions,
  selectDefaultVersion,
  selectedVersion,
  setVersion
} from '../../store/versions-data/store';
import {map} from 'rxjs/operators';

interface AnnotatedVersion extends Version {
  isDefault: boolean;
}

@Component({
  selector: 'app-select-version',
  template: `
    <mat-form-field>
      <mat-label i18n>Version</mat-label>
      <mat-select (selectionChange)="selectionChange($event)"
                  [value]="selectedVersion$ | async"
                  [compareWith]="compareVersionHash"
      >
        <mat-option *ngFor="let version of availableVersions$ | async" [value]="version">
          {{ this.renderVersion(version) }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  `,
  styles: [],
})
export class SelectVersionComponent implements OnInit {
  selectedVersion$: Observable<AnnotatedVersion>;
  availableVersions$: Observable<AnnotatedVersion[]>;
  @Output() versionChanged: EventEmitter<Version> = new EventEmitter<Version>();

  constructor(
    private readonly store: Store<AppState>,
  ) {
  }

  private annotateVersions(versions: Version[], defaultVersion: Version): AnnotatedVersion[] {
    return versions.map((v) => this.annotateVersion(v, defaultVersion))
      .sort((a, b) => b.displayName.localeCompare(a.displayName));
  }

  private annotateVersion(version: Version, defaultVersion: Version): AnnotatedVersion {
    if (version.hash === defaultVersion.hash) {
      return {...version, isDefault: true};
    }
    return {...version, isDefault: false};
  }

  renderVersion(version: AnnotatedVersion): string {
    if (version.isDefault) {
      return `${version.displayName} (default)`;
    }
    return version.displayName;
  }

  compareVersionHash(option: AnnotatedVersion, value: AnnotatedVersion): boolean {
    return option.hash === value.hash;
  }

  ngOnInit(): void {
    const defaultVersion$ = this.store.pipe(
      select(selectDefaultVersion),
    );

    this.selectedVersion$ = combineLatest([this.store.pipe(select(selectedVersion)), defaultVersion$]).pipe(
      map(([version, defaultVersion]) => this.annotateVersion(version, defaultVersion)),
    );
    this.availableVersions$ = combineLatest([this.store.pipe(select(selectAvailableVersions)), defaultVersion$]).pipe(
      map(([versions, defaultVersion]) => this.annotateVersions(versions, defaultVersion)),
    );
  }

  selectionChange(event: MatSelectChange): void {
    const version: Version = event.value;
    this.store.dispatch(setVersion({version}));
    this.versionChanged.emit(version);
  }
}
