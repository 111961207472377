import { Injectable } from '@angular/core';
import { Material } from '../models/material.models';
import { MaterialType } from '../../../store/reference-data/models/reference-data.model';

@Injectable({
  providedIn: 'root',
})
export class MergeMaterialsService {

  constructor() { }

  merge(newMaterial: Partial<Material>, storedMaterial: Partial<Material>): Material {
    const merged = {
      ...storedMaterial,
      ...newMaterial,
      conversionProcess: {
        ...storedMaterial.conversionProcess,
        ...newMaterial.conversionProcess,
      },
      mass: {
        ...storedMaterial.mass,
        ...newMaterial.mass,
      },
    };
    if (this.shouldResetConversionProcess(newMaterial?.materialType, storedMaterial?.materialType)) {
      merged.conversionProcess = null;
    }
    return merged;
  }

  private shouldResetConversionProcess(newType: MaterialType | undefined, storedType: MaterialType | undefined): boolean {
    return !!newType?.label && newType?.label !== storedType?.label;
  }
}
