import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store';
import * as fromStatistics from '../../store/statistics-data/store';
import { Statistics } from '../../store/statistics-data/models/statistics-data.model';

@Component({
  selector: 'app-statistics-table',
  template: `
    <div class="container">
      <div class="row">
        <mat-card>
          <mat-card-content>
            <div class="icon">
              <mat-icon inline>folder</mat-icon>
            </div>
            <div class="number">
              {{ statistics.projects }}
            </div>
            <div class="label">Projects</div>
          </mat-card-content>
        </mat-card>
        <mat-card>
          <mat-card-content>
            <div class="icon">
              <mat-icon inline>folder</mat-icon>
              <mat-icon inline class="smaller">schedule</mat-icon>
            </div>
            <div class="number">
              {{ statistics.monthlyActiveProjects }}
            </div>
            <div class="label">
              Active projects
              <mat-icon
                class="tooltip"
                color="primary"
                i18n-matTooltip
                matTooltip="Number of projects which have been modified in at least once within last month."
                matTooltipClass="recyclability-help-box"
              >
                help
              </mat-icon>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="row">
        <mat-card>
          <mat-card-content>
            <div class="icon">
              <mat-icon inline>supervisor_account</mat-icon>
            </div>
            <div class="number">
              {{ statistics.enabledUsers }}
            </div>
            <div class="label">Users</div>
          </mat-card-content>
        </mat-card>
        <mat-card>
          <mat-card-content>
            <div class="icon">
              <mat-icon inline>supervisor_account</mat-icon>
              <mat-icon inline class="smaller">schedule</mat-icon>
            </div>
            <div class="number">
              {{ statistics.monthlyActiveUsers }}
            </div>
            <div class="label">
              Active users
              <mat-icon
                class="tooltip"
                color="primary"
                i18n-matTooltip
                matTooltip="Number of users who logged in at least once within last month."
              >
                help
              </mat-icon>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="row">
        <mat-card>
          <mat-card-content>
            <div class="icon">
              <mat-icon inline>library_books</mat-icon>
            </div>
            <div class="number">
              {{ statistics.projectScenarios }}
            </div>
            <div class="label">Scenarios</div>
          </mat-card-content>
        </mat-card>
        <mat-card>
          <mat-card-content>
            <div class="icon">
              <mat-icon inline>public</mat-icon>
            </div>
            <div class="number">
              {{ statistics.publishedScenarios }}
            </div>
            <div class="label">Published scenarios</div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  `,
  styleUrls: [ 'statistics-table.component.scss' ],
})
export class StatisticsTableComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  statistics$: Observable<Statistics>;
  statistics: Statistics;

  constructor(private readonly store: Store<AppState>) {
  }

  ngOnInit(): void {
    this.store.dispatch(fromStatistics.getStatistics());
    this.statistics$ = this.store.pipe(
      select(fromStatistics.selectStatisticsData),
    );
    this.subscription.add(this.statistics$.subscribe((stats) => {
      this.statistics = stats;
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
