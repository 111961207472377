import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Collaborator} from '../../collaborator/collaborator.model';
import {HttpClient} from '@angular/common/http';
import {CollaboratorMapperService} from '../../collaborator/collaborator-mapper.service';
import {Observable} from "rxjs";
import {CollaboratorListDto} from "../../api/dtos/__generated__/collaboratorListDto";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root',
})
export class UserManagementDataService {

  COLLABORATOR_URL = `${environment.apiUrl}/collaborators`;
  me: Collaborator;

  constructor(
    private readonly http: HttpClient,
    private readonly mapper: CollaboratorMapperService,
  ) {
  }
  getAll(): Observable<Collaborator[]> {
    return this.http.get(`${this.COLLABORATOR_URL}?includeDisabled=true`).pipe(
      map((dto: CollaboratorListDto) => this.mapper.fromList(dto.collaborators))
    );
  }

}
