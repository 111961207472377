import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {UserTableComponent} from '../../user-management/user-table/user-table.component';
import {Collaborator} from '../../../collaborator/collaborator.model';
import {AppState} from '../../../store';
import {Store} from '@ngrx/store';
import * as fromUserManagement from '../../user-management/store';

@Component({
  selector: 'app-user-management-page',
  templateUrl: './user-management-page.component.html',
  styleUrls: ['./user-management-page.component.scss'],
})
export class UserManagementPageComponent implements OnInit, AfterViewInit {

  @ViewChild(UserTableComponent)
  userTable: UserTableComponent;
  dataSource: MatTableDataSource<Collaborator>;

  constructor(private readonly store: Store<AppState>) {
  }

  ngOnInit(): void {
    this.store.dispatch(fromUserManagement.getAllCollaborators());
  }

  ngAfterViewInit(): void {
    this.dataSource = this.userTable.dataSource;
  }

  applyFilter(event: Partial<Event>) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
