import { Injectable } from '@angular/core';
import { UserManagementFeature } from './collaborator.model';
import { AbstractMapper } from '../project/abstract-mapper';
import { UserManagementFeatureDto } from '../api/dtos/__generated__/userManagementFeatureDto';

@Injectable({
  providedIn: 'root',
})
export class UserManagementFeatureMapperService extends AbstractMapper<UserManagementFeatureDto, UserManagementFeature> {

  constructor() {
    super();
  }

  from(dto: UserManagementFeatureDto): UserManagementFeature {
    if (!dto) {
      return null;
    }
    const { enabled, userPoolId } = dto;
    return { enabled, userPoolId };
  }

  to(feature: UserManagementFeature): UserManagementFeatureDto {
    if (!feature) {
      return null;
    }
    const { enabled, userPoolId } = feature;
    return { enabled, userPoolId };
  }
}
