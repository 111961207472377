import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';
import {getAllCollaborators, getAllCollaboratorsError, getAllCollaboratorsSuccess} from './user-management.actions';
import {Collaborator} from '../../../collaborator/collaborator.model';

export const userManagementFeatureKey = 'userManagement';

export interface UserManagementState extends EntityState<Collaborator> {
  // additional entities state properties
  loading: boolean;
  error: string;
}

export interface UserManagementFeatureState {
  userManagement: UserManagementState;
}

export const adapter: EntityAdapter<Collaborator> = createEntityAdapter<Collaborator>();

export const initialState: UserManagementState = adapter.getInitialState({
  loading: false,
  error: null,
});

export const reducer = createReducer(
  initialState,
  on(getAllCollaborators, (state) =>
    ({...state, loading: true, error: null})
  ),
  on(getAllCollaboratorsError, (state, action) =>
    ({...state, loading: false, error: action.message}),
  ),
  on(getAllCollaboratorsSuccess, (state, action) => {
    return adapter.setAll(
      action.collaborators,
      {...state, loading: false, error: null},
    );
  }),
);
