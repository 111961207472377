import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType, OnInitEffects} from '@ngrx/effects';
import {getAllCollaborators, getAllCollaboratorsError, getAllCollaboratorsSuccess} from './user-management.actions';
import {of, switchMap} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Collaborator} from '../../../collaborator/collaborator.model';
import {UserManagementDataService} from '../user-management.data-service';

@Injectable({
  providedIn: 'root'
})
export class UserManagementEffects {
  getAllCollaborators$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllCollaborators.type),
      switchMap(() => this.userManagementService.getAll()
        .pipe(
          map((collaborators: Collaborator[]) => getAllCollaboratorsSuccess({collaborators})),
          catchError(() => of(getAllCollaboratorsError({message: 'Could not fetch user-management data'}))),
        )
      ),
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly userManagementService: UserManagementDataService,
  ) {
  }
}
