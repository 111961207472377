import { Injectable } from '@angular/core';
import { Collaborator } from './collaborator.model';
import { CollaboratorDto } from '../api/dtos/__generated__/collaboratorDto';
import { AbstractMapper } from '../project/abstract-mapper';
import { FeaturesMapperService } from './features-mapper.service';

@Injectable({
  providedIn: 'root',
})
export class CollaboratorMapperService extends AbstractMapper<CollaboratorDto, Collaborator> {

  constructor(private readonly featuresMapperService: FeaturesMapperService) {
    super();
  }

  from(dto: CollaboratorDto): Collaborator {
    if (!dto) {
      return null;
    }
    const { id, firstname, lastname, isAdmin, enabled, features } = dto;
    return { id, firstname, lastname, isAdmin, enabled, features: this.featuresMapperService.from(features) } as Collaborator;
  }

  to(collaborator: Collaborator): CollaboratorDto {
    if (!collaborator) {
      return null;
    }
    const { id, firstname, lastname, isAdmin, enabled, features } = collaborator;
    return { id, firstname, lastname, isAdmin, enabled, features: this.featuresMapperService.to(features) };
  }
}
