import { Injectable } from '@angular/core';
import { Features } from './collaborator.model';
import { AbstractMapper } from '../project/abstract-mapper';
import { FeaturesDto } from '../api/dtos/__generated__/featuresDto';
import { UserManagementFeatureMapperService } from './user-management-feature-mapper.service';

@Injectable({
  providedIn: 'root',
})
export class FeaturesMapperService extends AbstractMapper<FeaturesDto, Features> {

  constructor(private readonly userManagementFeatureMapperService: UserManagementFeatureMapperService) {
    super();
  }

  from(dto: FeaturesDto): Features {
    if (!dto) {
      return null;
    }
    const { userManagement } = dto;
    return { userManagement: this.userManagementFeatureMapperService.from(userManagement) };
  }

  to(features: Features): FeaturesDto {
    if (!features) {
      return null;
    }
    const { userManagement } = features;
    return { userManagement: this.userManagementFeatureMapperService.to(userManagement) };
  }
}
