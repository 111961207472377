import {createAction, props} from '@ngrx/store';
import {Collaborator} from '../../../collaborator/collaborator.model';

export const getAllCollaborators = createAction(
  '[UserManagement/API] Get all collaborators',
);

export const getAllCollaboratorsSuccess = createAction(
  '[UserManagement/API] Get all collaborators success',
  props<{ collaborators: Collaborator[] }>(),
);

export const getAllCollaboratorsError = createAction(
  '[UserManagement/API] Get all collaborators error',
  props<{ message: string }>(),
);
