<mat-card class="end-of-life-card" [formGroup]="form">
  <mat-card-title>
      <h3 class="country-title" i18n>{{endOfLife.distributionMarketCountry}}</h3>
  </mat-card-title>
  <mat-card-content>
    <div class="row" id="recyclable-group">
      <div id="recyclable-toggle">
        <mat-label i18n>Is the material recyclable?</mat-label>
        <mat-slide-toggle color="primary" formControlName="recyclable"></mat-slide-toggle>
        <span *ngIf="form.controls.recyclable.value; else no" i18n>Yes</span>
        <mat-icon
          class="tooltip"
          color="primary"
          i18n-matTooltip
          matTooltip="Please specify if the material can be considered recyclable in an existing recycling stream and does not act as a recycling disruptor (for further information, please refer to the EMF recycling definition in the documentation)"
        >
          help
        </mat-icon>
      </div>
      <div id="recycling-rate-group">
        <div id="recycling-rate-description">

          <mat-label i18n id="recycling-rate-label">Recycling rate</mat-label>
          <mat-icon
            class="tooltip"
            color="primary"
            i18n
            i18n-matTooltip
            matTooltip="Default recycling rate corresponds to the recycling rate per material and distribution country based on available statistics. A custom recycling rate can be entered when data better reflecting the scenario is available."
          >
            help
          </mat-icon>
        </div>
        <div class="row">
          <mat-button-toggle-group
            #defaultRecyclingRate="matButtonToggleGroup"
            (change)="updateUseOfDefaultRecyclingRate($event)"
            [disabled]="!endOfLife.recyclable"
            [value]="recyclingRateType"
            name="defaultRecyclingRate">
            <mat-button-toggle [value]="DEFAULT" i18n>Default</mat-button-toggle>
            <mat-button-toggle [value]="CUSTOM" i18n>Custom</mat-button-toggle>
          </mat-button-toggle-group>
          <div class="percentage-form">
            <mat-form-field id="percentage-form-field">
              <mat-label i18n>Percentage</mat-label>
              <input
                formControlName="recyclingRate"
                matInput
                type="number"
                [disabled]="!form.controls.recyclable.value"
              >
              <span matSuffix>%</span>
            </mat-form-field>
            <ng-container *ngIf="shouldDisplayRecyclingRateWarning">
              <span id="default-recycling-rate-warning" class="color-warn">Area of distribution and material type are required to determine
                the default recycling rate.
              </span>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <mat-label i18n>Is the material compostable?</mat-label>
      <mat-slide-toggle color="primary" formControlName="compostable"></mat-slide-toggle>
      <span *ngIf="form.controls.compostable.value; else no" i18n>Yes</span>
    </div>
  </mat-card-content>
</mat-card>

<ng-template #no>
  <span i18n>No</span>
</ng-template>
