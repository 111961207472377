import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatisticsHeaderModule } from './header/statistics-header.module';
import { StatisticsTableModule } from './table/statistics-table.module';
import { StatisticsDataModule } from '../store/statistics-data/statistics-data.module';

@NgModule({
  declarations: [],
  exports: [
    StatisticsHeaderModule,
    StatisticsTableModule,
  ],
  imports: [
    CommonModule,
    StatisticsHeaderModule,
    StatisticsTableModule,
    StatisticsDataModule,
  ]
})
export class StatisticsModule {
}
