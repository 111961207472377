import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {AppState} from '../../../store';
import {select, Store} from '@ngrx/store';
import * as fromUserManagement from '../store';
import {Collaborator} from '../../../collaborator/collaborator.model';

@Component({
  selector: 'app-users-table',
  templateUrl: './user-table.component.html',
  styleUrls: ['./user-table.component.scss'],
})
export class UserTableComponent implements OnInit, AfterViewInit, OnDestroy {

  private readonly subscription = new Subscription();
  dataSource: MatTableDataSource<Collaborator>;

  displayedColumns = [
    'enabled',
    'firstname',
    'lastname',
    'isAdmin',
  ];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private readonly store: Store<AppState>) {}

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<Collaborator>([]);
    this.subscription.add(
      this.store.pipe(select(fromUserManagement.selectAll)).subscribe(collaborators => {
        this.dataSource.data = collaborators;
      }),
    );
  }

  trackBy(index: number, collaborator: Collaborator): string {
    return collaborator.id;
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
