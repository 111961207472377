import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../../../../auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { CollaboratorDataService } from '../../../../collaborator/collaborator.data-service';
import { Collaborator } from '../../../../collaborator/collaborator.model';
import { PdfDialogComponent } from '../../../../shared/documentation/pdf-dialog.component';

@Component({
  selector: 'app-profile-button',
  template: `
    <button
      [matMenuTriggerFor]="profileActionsMenu"
      class="background-accent hover-background-primary"
      id="profile-button"
      data-testid="profile-button"
      mat-fab
    >{{ me$ | async | initials }}
    </button>

    <mat-menu #profileActionsMenu="matMenu">
      <button (click)="openDocumentation()" mat-menu-item data-testid="open-documentation-button">
        <mat-icon class="color-accent">description</mat-icon>
        <span i18n>eQopack documentation</span>
      </button>
      <button (click)="goToExcelDoc()" mat-menu-item data-testid="open-documentation-appendix-button">
        <mat-icon class="color-accent">table_view</mat-icon>
        <span i18n>Documentation appendix</span>
      </button>
      <button (click)="openUserGuide()" mat-menu-item data-testid="open-user-guide-button">
        <mat-icon class="color-accent">description</mat-icon>
        <span i18n>eQopack user guide</span>
      </button>
      <button (click)="openCommGuidelines()" mat-menu-item data-testid="open-user-guide-button">
        <mat-icon class="color-accent">description</mat-icon>
        <span i18n>Communication guidelines</span>
      </button>
      <button (click)="openCommGuidelinesVisuals()" mat-menu-item data-testid="open-user-guide-button">
        <mat-icon class="color-accent">description</mat-icon>
        <span i18n>Communication guidelines (visuals)</span>
      </button>
      <button (click)="changelog()" mat-menu-item data-testid="changelog-button">
        <mat-icon class="color-accent">new_releases</mat-icon>
        <span i18n>Changelog</span>
      </button>
      <button (click)="logout()" mat-menu-item data-testid="logout-button">
        <mat-icon class="color-accent">logout</mat-icon>
        <span i18n>Logout</span>
      </button>
    </mat-menu>
  `,
  styles: [ `
    #profile-button {
      font-size: 1.5rem !important;
      font-weight: 500;
      transform: none;
    }
  ` ]
})
export class ProfileButtonComponent implements OnInit {

  me$: Observable<Collaborator>;

  constructor(
    private readonly authService: AuthService,
    private readonly collaboratorService: CollaboratorDataService,
    private readonly router: Router,
    private readonly matDialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.me$ = this.collaboratorService.getMe();
  }

  logout(): void {
    this.authService.logout()
      .subscribe(_ => this.router.navigate([ '/login' ], {
          replaceUrl: true,
          queryParams: { redirectUrl: '/' },
        }),
      );
  }

  changelog(): void {
    this.router.navigate([ '/changelog' ]);
  }

  openDocumentation(): void {
    this.matDialog.open(PdfDialogComponent, {
      data: '/assets/docs/eQopack_DocumentationReport_v2.12.pdf',
    });
  }

  openUserGuide(): void {
    this.matDialog.open(PdfDialogComponent, {
      data: '/assets/docs/eQopack_User_guide.pdf',
    });
  }

  goToExcelDoc(): void {
    window.open('/assets/docs/eQopack_DocumentationAppendix_v2.12.xlsx', '_blank');
  }

  openCommGuidelines() {
    this.matDialog.open(PdfDialogComponent, {
      data: '/assets/docs/eQopack Communications Guidelines_150524.pdf',
    });
  }

  openCommGuidelinesVisuals() {
    this.matDialog.open(PdfDialogComponent, {
      data: '/assets/docs/eQopack Comms Guidelines_3.4.24.pdf',
    });
  }
}
